import React from 'react';
import './App.css';

// component import's
import HomeComponent from './components/HomeComponent/HomeComponent';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PujaGalleryComponent from './components/PujaGalleryComponent/PujaGalleryComponent';
import GetBooksGalleryComponent from './components/GetBooksGalleryComponent/GetBooksGalleryComponent';
import MakeDonationGalleryComponent from './components/MakeDonationGalleryComponent/MakeDonationGalleryComponent';
import MyPujaBookingComponent from './components/MyPujaBookingComponent/MyPujaBookingComponent';
import OrderBooksComponent from './components/OrderBooksComponent/OrderBooksComponent';
import MyDonationComponent from './components/MyDonationComponent/MyDonationComponent';
import TempleGalleryComponent from './components/TempleGalleryComponent/TempleGalleryComponent';
import GalleryPreviewComponent from './components/GalleryPreviewComponent/GalleryPreviewComponent';
import BookDescriptionComponent from './components/BookDescriptionComponent/BookDescriptionComponent';
import PujaSuccess from "./components/PujaSuccessComponent/pujaSuccess";

function App() {

  return (
    <Router basename='/'>
    {/* <Router basename="/Ashram"> */}
      <Routes>
        <Route exact path="/" element={<HomeComponent />} />
        <Route path="puja-gallery" element={<PujaGalleryComponent />} />
        <Route path="get-books" element={<GetBooksGalleryComponent />} />
        <Route path="puja-success" element={<PujaSuccess />} />
        <Route path="make-a-donation" element={<MakeDonationGalleryComponent />} />
        <Route path="puja-gallery/my-puja-booking/:pujaID" element={<MyPujaBookingComponent />} />
        <Route path="get-books/my-books-orders" activeClassName="active" element={<OrderBooksComponent />} />
        <Route path="get-books/book-description" activeClassName="active" element={<BookDescriptionComponent />} />
        <Route path="make-a-donation/my-donation" activeClassName="active" element={<MyDonationComponent />} />
        <Route path="temple-gallery" activeClassName="active" element={<TempleGalleryComponent />} />
        <Route path="temple-gallery/gallery-preview" activeClassName="active" element={<GalleryPreviewComponent />} />
      </Routes>
    </Router>
  );
}

export default App;
