import React from 'react';
import { 
  Box, 
  Typography 
} from '@mui/material';
import '../FooterComponent/FooterComponent.css';

export default function FooterComponent() {
  return (
    <>
        <Box className="copyright-content" sx={{ textAlign: 'center', py: 3 }}>
            <Typography sx={{ color: '#686868' }}>Copyright © 2024. All Rights Reserved.</Typography>
        </Box>
    </>
  )
}
